import { apiCall } from "../../api";
import { baseUrl } from "../../contant";

export const contact_dispatch = (data, successCb, failureCallback) => async (dispatch) => {
  let config = {
      method: "post",
      url: `${baseUrl}client/send_message_web`,
      data,
  };

  let response = await apiCall(config, dispatch);
  if (response?.data?.status) {
      successCb(response?.data);
      // console.log("res",response.data)
  } else {
      failureCallback(response?.data)
      // console.log("res",response.data)
  }

};