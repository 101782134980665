import React, { useState } from "react";
import "../Header/Header.css";
import logo from "../Assets/Your-season-Final-Logo-v1/Your-season-Final-Logo-v1.png";
import appstore from "../Assets/appstore.png";
import googlePlay from "../Assets/googlePlay.png";
// import playstore from "../Assets/appstore.svg";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

{/* <HashLink
className={`nav-link links ${
  location.hash === "#howtouse" && "activeLink"
}`}
smooth
to="/#howtouse"
scroll={(el) => scrollWithOffset(el)}

> */}

const Header = () => {
  const [state, setState] = useState("collapse");
  const [navToggle, setNavToggle] = useState("")

  const navbarToggler = () => {
    if (navToggle === "") {
      setNavToggle("show")
    } else {
      setNavToggle("")
    }
  }

  const clickee = () => {
    navbarToggler()
    // document.getElementsByClassName("navbar-collapse justify-content-md-center RightNavbar mt-4 collapse ")
    if (state === "collapse") {
      setState("show");
    } else {
      setState("collapse");
    }
  };



  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const location = useLocation()

  console.log(location, "locj");

  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-dark header">
      <div class="container-fluid headerContainer">
        {/* <a class="navbar-brand" href="#">Navbar</a> */}
        <div className="logocontainer">
          <img src={logo} className="logo"></img>
        </div>

        {/* <div className='d-flex justify-content-center align-item-center flex-row'> */}
        <div
          class={`collapse ${navToggle} navbar-collapse flex-1 menuContainer ${state ? "bg-dark" : ""
            } `}
          id="navbarNavAltMarkup"
        >
          <div class="navbar-nav">
            <HashLink class={`nav-link active ${location.hash === "#Home" && "active-tab"} ${location.hash === "" && "active-tab"}`}
              smooth
              to="/#Home"
              scroll={(el) => scrollWithOffset(el)}
              aria-current="page"
              onClick={navbarToggler}
            >
              <li

              >
                Home
              </li>
            </HashLink>
            <HashLink class={`nav-link ${location.hash === "#About-Us" && "active-tab"}`}
              smooth
              to="/#About-Us"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li>About Us</li>
            </HashLink>
            <HashLink class={`nav-link ${location.hash === "#Services" && "active-tab"}`}
              smooth
              to="/#Services"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li>Services</li>
            </HashLink>
            <HashLink class={`nav-link ${location.hash === "#Download" && "active-tab"}`}
              smooth
              to="/#Download"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li>Download</li>
            </HashLink>
            {/* <HashLink class={`nav-link ${location.hash === "#Download" && "active-tab"}`}
              smooth
              to="/termsCondition"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li> Terms & Conditions</li>
            </HashLink>
            <HashLink class={`nav-link ${location.hash === "#Download" && "active-tab"}`}
              smooth
              to="/privacypolicy"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li>  Privacy Policy</li>
            </HashLink> */}
            <HashLink class={`nav-link ${location.hash === "#Contact-Us" && "active-tab"}`}
              smooth
              to="/#Contact-Us"
              scroll={(el) => scrollWithOffset(el)}
              onClick={navbarToggler}
            >
              <li



              >Contact Us</li>
            </HashLink>
          </div>
          <div className={`playstoreContainers show ${state ? "bg-dark" : ""}`}>
            <div className="googlePlay">
              <a href="https://play.google.com" target="blank">
                <img src={googlePlay} />
              </a>
            </div>
            <div className="playStore">
              <a href="https://www.apple.com/in/app-store/" target="blank">
                <img src={appstore} />
              </a>
            </div>

          </div>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={clickee}
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default Header;
