import React, { useId, useState } from "react";
import "./LandingPage.css";
import Header from "../Header/Header";
import logo from "../Assets/Your-season-Final-Logo-v1/Your-season-Final-Logo-v1-1.png";
import outfit from "../Assets/first_circle.png";
import collection from "../Assets/Third_circle.png";
import stylist from "../Assets/Second_circle.png";
import VideoImage from "../Assets/VideoImg.png";
import mainLogo from "../Assets/main_logo.png";
import appstore from "../Assets/appstore.png";
import googlePlay from "../Assets/googlePlay.png";
import { HashLink } from "react-router-hash-link";
import { PiDotOutlineFill } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { contact_dispatch } from "../redux/action/landingPage";


const LandingPage = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handlevalue = (e, key) => {
    setContact({ ...contact, [key]: e.target.value })
  }
  const handleContact = () => {

    toast.dismiss();
    const emailRegex = /\S+@\S+\.\S+/;
    if (contact.name === "") {
      toast.error("Please enter your name");
      return;
    }
    if (contact.email === "") {
      toast.error("Please enter your email id");
      return;
    }
    if (contact.message === "") {
      toast.error("Please type message");
      return;
    }

    if (!emailRegex.test(contact.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    setLoading(true)
    const payload = {
      full_name: contact.name,
      email: contact.email,
      message: contact.message,
    }

    const successCb = (res) => {
      toast.success("Contact form submitted successfully.");
      setContact({
        name: "",
        email: "",
        message: "",
      });
      setLoading(false)
    }
    const failureCb = (res) => {
      toast.error(res?.message)
    }
    dispatch(contact_dispatch(payload, successCb, failureCb))
  }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  function getOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else if (/Windows/i.test(userAgent)) {
      return "Windows";
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return "Mac";
    } else if (/Linux/i.test(userAgent)) {
      return "Linux";
    }

    return "Unknown";
  }


  function redirectToAppStore() {
    const isIOS = getOperatingSystem();
    console.log("navigator.userAgent", navigator.userAgent);
    if (isIOS == "iOS" || isIOS == "Mac") {
      // Open the App Store link in a new tab
      window.open("https://www.apple.com/in/app-store/", "_blank");
    } else {
      // Open the Google Play link
      window.open("https://play.google.com", "_blank");
    }
  }
  return (
    <section className="landing-page">
      {/* <div className='container-fluid'> */}
      <div className="row gx-0 ">
        <div className="col-12">
          {/* ============== First Portion ================ */}

          <div className="backGround-room " id="Home">
            <div className="image-overLay">
              <Header></Header>
              {/* <img src={RoomImage} className="roomImage"></img> */}
              <div className="first-text" >
                <div className="boxborder">
                  <div className="collection_text" style={{ color: "#D0A443" }}>
                    YOUR SEASON
                  </div>
                  <div className="below-collection text-default" style={{ color: "#D0A443", marginBottom: "5px" }}>
                    <b > Privately share your collections with all clients in One upload</b>
                  </div>
                  <div style={{ fontSize: "16px", color: "#D0A443", margin: "15px 0" }} >  No more looking for where the photo stream is or<br />
                    having to send to each individual client<br /></div>
                  <div className="below-collection text-default d-flex justify-content-center" style={{ color: "#D0A443" }}>
                    <div className=" col-sm-12 col-md-6 " style={{ textAlign: "left" }}>
                      <PiDotOutlineFill />ONE UPLOAD<br />
                      <PiDotOutlineFill />CLIENTS PRIVACY RESPECTED
                    </div>
                    <div className=" col-sm-12 col-md-6 " style={{ textAlign: 'left' }}>
                      <PiDotOutlineFill />EFFICIENT AND EASY<br />
                      <PiDotOutlineFill />Your time saved,
                      YOUR SEASON<br />

                    </div>
                  </div>
                </div>
                <button className="try-for border-0 px-5 py-2" onClick={redirectToAppStore}>
                  Try for free
                </button>
              </div>

            </div>
          </div>

          {/* =========== Second Portion ============ */}

          <div className="circle-div " >
            <div className=" circle-text d-flex justify-content-center flex-column text-center">
              <div className="awesome-features mb-4">How it Works for a Stylist...</div>
              {/* <div className="awesome-feature-belowText mb-5">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor <br />
                invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua.
              </div> */}
              <div className="d-flex justify-content-around mb-3 ">
                <div>
                  <img src={outfit} className="feature_image_size"></img>
                  <div className="explore-outfits">UPLOAD</div>
                  <div className="explore-outfits-text">
                    Add as many photos <br />
                    to an album<br />
                    as you want.
                  </div>
                </div>
                <div>
                  <img src={collection} className="feature_image_size"></img>
                  <div className="explore-outfits">SHARE WITH CLIENTS</div>
                  <div className="explore-outfits-text">
                    Choose if you want to share with <br />all or select clients.
                    <br />They receive the stream as a <br />private album.<br />
                    Privcy respected.
                  </div>
                </div>
                <div>
                  <img src={stylist} className="feature_image_size"></img>
                  <div className="explore-outfits">CHATS & SELL</div>
                  <div className="explore-outfits-text">
                    Communicate privately with <br /> any clients interested <br />and confirm orders.

                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center   contact-usMedia mt-5" onClick={redirectToAppStore}>
                <button className="col-2 text-center contact-us-btn">
                  Contact Us
                </button>
              </div>
            </div>
          </div>

          {/* ============= Third Portion ============== */}

          <div className="video-div py-4 px-5" id="Services">
            <img src={VideoImage} className="videoImage"></img>
          </div>

          {/* ========== Fourth Portion =============  */}

          <div className=" why-season-div whySeason " id="About-Us">
            <div className="why-season mb-4" style={{ color: "#D0A443" }}>Why YOUR SEASON?</div>

            <div className="why-season-details-container">
              <div className="d-flex  why-season-details mb-4 WhyCon">
                <div className="text-default col-md-7 col-lg-7 d-flex  flex-column why-season-all-text">
                  <div className="mb-4 why-season-detail">
                    YOUR SEASON was created out of a need to find the most efficient way to share collections with a group of clients in one upload while maintaining their privacy.<br /><br />
                    "I have 50 clients that I want to share the newest arrivals with before they sell out..."
                  </div>
                  <div className="explore-outfits weight" >
                    YOUR SEASON is the answer
                  </div>
                  <div className="why-season-detail">
                    Upload your photos once and share with all in one easy step. Your clients receive the album as a text link which then directs them to the album in the YOUR SEASON app. No other clients names are seen and your client's privacy is respected.<br /><br />
                    You can then communicate directly with a client on price, size, etc. Order can be confirmed directly in the app.<br /><br />
                    From a clients perspective, they can keep track and follow all of their favorite stylists from any store they shop.<br /><br />
                    They receive new items/new collections in a fast and easy way and never have to wonder where a photo stream is or wait for it to download.
                  </div><br />
                  <div className="explore-outfits weight" >
                    Your time saved... YOUR SEASON
                  </div>
                  <div className="downloadButton" onClick={redirectToAppStore}>
                    <button className="download-btn">Download</button>
                  </div>
                </div>


                <div className="why-season-container-image col-md-5 col-lg-5 d-flex justify-content-between" style={{ height: "100%" }}>
                  <img src={logo} style={{ width: "100%", objectFit: "contain" }}></img>
                </div>
              </div>

            </div>
          </div>

          {/* ===========  Fifth Portion ============   */}

          <div className="download-section" id="Download">
            <div className="download-app-div">
              <div className="text-default download-app mb-3">
                Download the App Now
              </div>

              <div className="text-default mb-3 download-app-detail">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy
                <br /> eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed
                <br /> diam voluptua.
              </div>
              <div className="playStoreImage">
                <span>
                  <a href="https://play.google.com" target="blank">
                    <img src={googlePlay}></img>
                  </a>
                </span>
                <span className="appstore-container">
                  <a href="https://www.apple.com/in/app-store/" target="blank">
                    <img src={appstore}></img>
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* =========== Sixth Div ===========       */}

          <div className="form-div d-flex align-items-center justify-content-center contactUs-container" id="Contact-Us">
            {/* <div className="team-image-div">
              <img src={TeamImage} className="team-image"></img>
            </div> */}

            <div className="contact-us ">
              <div className="contact-us-text mb-3">Contact Us!</div>
              <div className="mb-4 contact-us-detail">
                <span style={{ color: "#D0A443" }}>YOUR SEASON </span> can help improve your client connections and expand your business in an easy and efficeint manner.
              </div>
              <div className="contact-us-info">
                <div className="d-flex flex-column">
                  <input
                    type="text"
                    className="mb-3 py-2 px-3 input-field"
                    value={contact?.name} onChange={(e) => handlevalue(e, 'name')}
                    placeholder="Name*"
                  ></input>
                  <input
                    type="text"
                    className="mb-3 py-2 px-3 input-field"
                    value={contact?.email}
                    onChange={(e) => handlevalue(e, 'email')}
                    placeholder="E-Mail*"
                  ></input>

                  <textarea
                    type="text"
                    className="mb-5 py-4 px-3 text-area"
                    value={contact?.message} onChange={(e) => handlevalue(e, 'message')}
                    placeholder="Message*"
                  ></textarea>
                  <div className="send-btn-container">
                    <button className="send-btn" onClick={() => handleContact()}> {loading ? 'Sending...' : 'Send'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* =========== Seventh Div ============   */}

          <div className="footer d-flex justify-content-center flex-column">
            <div className="d-flex justify-content-center mb-4 footer-image">
              <div className="d-flex justify-content-center ">
                <img src={mainLogo} className="footer-logo"></img>
              </div>
            </div>
            <div className="d-flex justify-content-between footer-options text-default ">
              <HashLink
                smooth
                to="/#Home"
                scroll={(el) => scrollWithOffset(el)}
              >
                Home
              </HashLink>
              <HashLink
                smooth
                to="/#About-Us"
                scroll={(el) => scrollWithOffset(el)}
              >
                About Us
              </HashLink>
              <HashLink
                smooth
                to="/#Services"
                scroll={(el) => scrollWithOffset(el)}
              >
                Services
              </HashLink>
              <HashLink
                smooth
                to="/#Download"
                scroll={(el) => scrollWithOffset(el)}
              >
                Download
              </HashLink>
              <HashLink
                smooth
                to="/termsCondition"
                scroll={(el) => scrollWithOffset(el)}
              >
                Terms & Conditions
              </HashLink>
              <HashLink
                smooth
                to="/privacypolicy"
                scroll={(el) => scrollWithOffset(el)}
              >
                Privacy Policy
              </HashLink>
              <HashLink
                smooth
                to="/#Contact-Us"
                scroll={(el) => scrollWithOffset(el)}
              >
                Contact Us!
              </HashLink>
            </div>
          </div>

          {/* ================ eight div ============== */}
          <div className="footer d-flex justify-content-center flex-column copyright">
            © 2023- All Rights Reserved - Your Season | Designed & Developed by marker
          </div>


        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default LandingPage;
