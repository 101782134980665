import React from 'react'
import mainLogo from "../Assets/main_logo.png";
import first from "../Assets/forgot.png"
import second from "../Assets/otp.png"
import third from "../Assets/pass.png"

function OTPscreen() {
  return (
    <div style={{background:"black" ,height:"100%", width:"100%"}}>
    <div className='circle-div otpHeight' style={{ height: "100vh !important" }}>
      <div className="d-flex justify-content-center pt-3 mb-5">
        <img src={mainLogo} className="footer-logos" height={"100rem"}></img>
      </div>
      <div className='row mx-0 '>
  
        <div className='col-sm-12 col-md-12 col-lg-4 text-center pb-2'>
          <div>

          <img src={first}  className='imgOTP'></img>
          </div>
        </div> 
        <div className='col-sm-12 col-md-12 col-lg-4  text-center pb-2'>
          <div>
          <img src={second} className='imgOTP' ></img>
          </div>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-4  text-center pb-2'>
          <div>
          <img src={third} className='imgOTP' ></img>
          </div>
        </div>

      </div>
    </div>
    </div>
  )
}

export default OTPscreen