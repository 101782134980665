import logo from './logo.svg';
import './App.css';
import LandingPage from './Landing Page/LandingPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; import OTPscreen from './Landing Page/OTPscreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TermsCondition from './Landing Page/TermsCondition';
import PrivacyPolicy from './Landing Page/PrivacyPolicy';
import { Provider } from 'react-redux';
import store from './store';
import { useEffect, useRef } from 'react';

function App() {

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes  >
            <Route exact={true} path='/' element={<LandingPage />} />
            <Route exact={true} path='/otp-screen' element={<OTPscreen />} />
            <Route exact={true} path='/termsCondition' element={<TermsCondition />} />
            <Route exact={true} path='/privacypolicy' element={<PrivacyPolicy />} />
          </Routes>

          {/* <LandingPage></LandingPage> */}
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </>

  );
}

export default App;
